if ('define' in window) {
define("discourse/theme-7/discourse/initializers/init-topic-excerpts", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(7);
  const themePrefix = key => `theme_translations.7.${key}`;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.modifyClass("component:topic-list-item", {
      pluginId: "discourse-air",
      expandPinned: true
    });
  });
});
}
