if ('define' in window) {
define("discourse/theme-7/discourse/initializers/customize-edit-category-general", ["exports", "@ember/runloop", "discourse/lib/plugin-api"], function (_exports, _runloop, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(7);
  const themePrefix = key => `theme_translations.7.${key}`;
  var _default = _exports.default = {
    name: "customize-edit-category-general",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.modifyClass("component:edit-category-general", {
          pluginId: "discourse-air",
          didInsertElement() {
            this._super(...arguments);
            document.body.classList.add("edit-category");
            this._focusCategoryName();
          },
          willDestroyElement() {
            this._super(...arguments);
            document.body.classList.remove("edit-category");
            this._laterFocus && (0, _runloop.cancel)(this._laterFocus);
          }
        });
      });
    }
  };
});
}
